import type { GetServerSideProps, NextPage } from "next";
import {
  ContentModule,
  getGlobals,
  getGraphqlSdk,
  GlobalProps,
  GraphQLResponseError
} from "@src/lib/services/server/contentful";
import type {} from "@src/lib/types";
import Chevron from "@/img/icons/chevron.svg";
import type {
  TrendReportQuery,
  TrendReportQueryVariables,
  TrendReportContentModulesQuery,
  TrendReportContentModules2Query
} from "@src/lib/services/server/contentful/generated/graphqlSdk";
import { logError } from "@src/lib/services/server/logger";
import Error from "next/error";
import { filterNull } from "@src/lib/utils";
import "setimmediate";
import { ProductLayout, ProductContainer } from "@src/components";
import { setCacheHeaders } from "@src/lib/utils/pageCache";
import { PageCacheGroups } from "@src/lib/types/fastly";
import { TrendReportContentModules } from "@src/components/TrendReportsContentModules";
import { useEffect, useState } from "react";
import React from "react";
import { Disclosure, Transition } from "@headlessui/react";

export type TrendReportParams = {
  slug: string;
};

export type TrendReportProps = GlobalProps & {
  entry?: TrendReportQuery;
  contentModules?: TrendReportContentModulesQuery;
  contentModules2?: TrendReportContentModules2Query;
};

export const TrendReport: NextPage<TrendReportProps> = ({
  entry,
  contentModules,
  contentModules2,
  ...layoutProps
}: TrendReportProps) => {
  const [stickyClass, setStickyClass] = useState("");
  const [stickyMobileClass, setStickyMobileClass] = useState("relative z-10");
  const [activeSection, setActiveSection] = useState("section-1-jumplink");
  const sectionsItem =
    contentModules && entry?.trendReportCollection
      ? entry.trendReportCollection.items[0]?.sectionsCollection
      : null;

  const entryItem = entry?.trendReportCollection
    ? entry.trendReportCollection.items[0]
    : null;

  const bgStyle =
    entry?.trendReportCollection?.items[0]?.backgroundStyle
      ?.toLowerCase()
      .split(" ")
      .join("") || "white";

  const contentModulesItem =
    contentModules && contentModules.trendReportCollection
      ? contentModules.trendReportCollection.items[0]
      : null;

  const contentModules2Item =
    contentModules2 && contentModules2.trendReportCollection
      ? contentModules2.trendReportCollection.items[0]
      : null;

  const sectionsItems = filterNull(sectionsItem?.items);

  const sectionsRef = React.useRef(
    sectionsItems.map(() => React.createRef<HTMLDivElement>())
  );

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  if (!entry) {
    return <Error statusCode={500} />;
  }

  const stickNavbar = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = "section-1-jumplink";

    sectionsRef.current.map(section => {
      const sectionOffsetTop = section.current?.offsetTop
        ? section.current.offsetTop - 300
        : section.current?.offsetTop;
      const sectionHeight = section.current?.offsetHeight;

      if (
        sectionOffsetTop &&
        sectionHeight &&
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.current.id;
      }
    });

    setActiveSection(newActiveSection);

    const windowHeight = window.scrollY;

    if (bgStyle == "white") {
      windowHeight > 50
        ? setStickyClass(
            "fixed top-[80px] lg:top-[92px] left-0 right-0 mx-auto z-10 bg-white py-5"
          )
        : setStickyClass("");
      windowHeight > 50
        ? setStickyMobileClass(
            "fixed top-[80px] left-0 right-0 mx-auto z-10 bg-white"
          )
        : setStickyMobileClass("relative z-10");
    } else {
      windowHeight > 50
        ? setStickyClass(
            "fixed top-[80px] lg:top-[92px] left-0 right-0 mx-auto z-10 bg-pr-yellow-200 py-5"
          )
        : setStickyClass("");
      windowHeight > 50
        ? setStickyMobileClass(
            "fixed top-[80px] left-0 right-0 mx-auto z-10 bg-pr-yellow-200"
          )
        : setStickyMobileClass("relative top-[80px] z-10");
    }
  };

  const bgClassNames: { [key: string]: string } = {
    white: "bg-white",
    yellow: "bg-pr-yellow-200"
  };
  const bgClassName = bgClassNames[bgStyle];
  console.log(entryItem?.seoTitle);

  const SEOfields = {
    seoTitle: entryItem?.seoTitle,
    seoDescription: entryItem?.seoDescription,
    seoImage: {
      description: entryItem?.seoImage?.description,
      url: entryItem?.seoImage?.url
    }
  };
  return (
    <div className={bgClassName}>
      <ProductLayout entry={SEOfields} {...layoutProps}>
        <section>
          <ProductContainer>
            <div className={`h-[100px]`}>
              <div
                className={`hidden flex-row flex-wrap justify-center gap-3 py-14 lg:flex lg:gap-5 ${stickyClass}`}
              >
                {sectionsItems.map((item, index) => {
                  const sectionHref = `#section-${index + 1}`;
                  const sectionId = `section-${index + 1}-jumplink`;
                  const defaultNavigationColor: string = "teal";
                  const navigationClassNames: { [key: string]: string } = {
                    red: "border-b-2 border-pr-red-500 ",
                    green: "border-b-2 border-pr-green-500",
                    yellow: "border-b-2 border-pr-yellow-500",
                    teal: "border-b-2 border-pr-teal-500",
                    black: "border-b-2 border-pr-dark-gray-500"
                  };
                  const navigationClassName =
                    navigationClassNames[
                      item.navigationColor
                        ? item.navigationColor.toLowerCase().split(" ").join("")
                        : defaultNavigationColor
                    ];
                  return (
                    <a
                      key={index}
                      href={sectionHref}
                      className={`text-pr-lg font-medium uppercase tracking-[0.54px] text-pr-dark-gray-500 lg:text-xl ${
                        activeSection === sectionId
                          ? `${navigationClassName} pb-[2px] lg:pb-1`
                          : ""
                      }`}
                    >
                      {item.heading}
                    </a>
                  );
                })}
              </div>
              <div
                className={`mx-auto flex w-full flex-col justify-center lg:hidden ${stickyMobileClass}`}
              >
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button>
                        <div className="flex flex-row items-center justify-center gap-5 py-4">
                          <div className={`flex`}>
                            {sectionsItems.map((item, index) => {
                              const sectionId = `section-${index + 1}-jumplink`;
                              const defaultNavigationColor: string = "teal";
                              const navigationClassNames: {
                                [key: string]: string;
                              } = {
                                red: "border-b-2 border-pr-red-500 ",
                                green: "border-b-2 border-pr-green-500",
                                yellow: "border-b-2 border-pr-yellow-500",
                                teal: "border-b-2 border-pr-teal-500",
                                black: "border-b-2 border-pr-dark-gray-500"
                              };
                              const navigationClassName =
                                navigationClassNames[
                                  item.navigationColor
                                    ? item.navigationColor
                                        .toLowerCase()
                                        .split(" ")
                                        .join("")
                                    : defaultNavigationColor
                                ];
                              return (
                                <div
                                  key={index}
                                  className={`mx-auto text-pr-lg font-medium uppercase tracking-[0.54px] text-pr-dark-gray-500 lg:text-xl ${
                                    activeSection === sectionId
                                      ? `${navigationClassName} block pb-[2px] lg:pb-1`
                                      : "hidden"
                                  }`}
                                >
                                  {item.heading}
                                </div>
                              );
                            })}
                          </div>
                          <Chevron
                            className={`size-4 stroke-current transition ${
                              open ? "rotate-180" : ""
                            }`}
                          />
                        </div>
                      </Disclosure.Button>

                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel>
                          {({ close }) => (
                            <div className="relative z-10 flex flex-col bg-white">
                              {sectionsItems.map((item, index) => {
                                const sectionHref = `#section-${index + 1}`;
                                if (item.heading) {
                                  return (
                                    <a
                                      onClick={() => {
                                        close();
                                      }}
                                      key={index}
                                      href={sectionHref}
                                      className={`text-pr-md border-b border-gray-200 py-3 text-center font-medium uppercase tracking-[0.54px] text-pr-dark-gray-500`}
                                    >
                                      {item.heading}
                                    </a>
                                  );
                                }
                              })}
                            </div>
                          )}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </ProductContainer>
        </section>
        {sectionsItems.map((item, index) => {
          const marginBottom = item.marginBottom
            ? `${item.marginBottom}px`
            : "100px";
          const marginBottomMobile = item.marginBottom
            ? `${item.marginBottom / 2}px`
            : "100px";
          const sectionHref = `section-${index + 1}`;
          const sectionHrefJump = `section-${index + 1}-jumplink`;
          const backgroundStyle = item.backgroundStyle
            ?.toLowerCase()
            .split(" ")
            .join("");
          const contentModulesItems = filterNull(
            contentModulesItem?.sectionsCollection?.items[index]
              ?.contentModulesCollection?.items
          );

          const contentModules2Items = filterNull(
            contentModules2Item?.sectionsCollection?.items[index]
              ?.contentModulesCollection?.items
          );

          type ContentModuleItem = (typeof contentModulesItems)[0] &
            (typeof contentModules2Items)[0];

          const combinedContentModules =
            contentModulesItems.map<ContentModuleItem>(
              (contentModule, count) => {
                if ("sys" in contentModule) {
                  return contentModule;
                } else {
                  return contentModules2Items[count] as ContentModuleItem;
                }
              }
            );

          const backgroundWrapperClassNames: { [key: string]: string } = {
            default: "",
            yellowbackground:
              "mx-auto w-full max-w-screen-4xl px-5 lg:px-8 2xl:px-24 pb-16",
            whitebackground:
              "mx-auto w-full max-w-screen-4xl px-5 lg:px-8 2xl:px-24 pb-16"
          };

          const backgroundClassNames: { [key: string]: string } = {
            default: "",
            yellowbackground: "bg-pr-yellow-200 pt-16 lg:pt-28",
            whitebackground: "bg-white pt-16 lg:pt-28"
          };
          const backgroundWrapperClassName =
            backgroundWrapperClassNames[
              backgroundStyle ? backgroundStyle : "default"
            ];
          let backgroundClassName =
            backgroundClassNames[backgroundStyle ? backgroundStyle : "default"];

          if (
            index == 0 &&
            (!backgroundStyle || backgroundStyle == "default")
          ) {
            backgroundClassName = "pt-14";
          }

          const hasBackground =
            backgroundStyle && backgroundStyle != "default" ? true : false;
          return (
            <div key={index} className={`${backgroundWrapperClassName}`}>
              <div
                ref={sectionsRef.current[index]}
                className={`${backgroundClassName}`}
                id={sectionHrefJump}
              >
                <a id={sectionHref} className="jumptarget"></a>
                <TrendReportContentModules
                  contentModules={combinedContentModules as ContentModule[]}
                  hasBackground={hasBackground}
                />
              </div>
              {/* Custom Spacing */}
              <div
                className="hidden md:block"
                style={{ height: marginBottom }}
              ></div>
              <div
                className="md:hidden"
                style={{ height: marginBottomMobile }}
              ></div>
            </div>
          );
        })}
      </ProductLayout>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<
  TrendReportProps,
  TrendReportParams
> = async context => {
  const { params, preview = false } = context;
  const props: TrendReportProps = {};

  if (!params) {
    return { notFound: true };
  }

  const { slug } = params;

  const query: TrendReportQueryVariables = { slug, preview };
  const graphqlSdk = getGraphqlSdk(preview);
  const trendReportQuery = graphqlSdk.trendReport(query);
  const trendReportContentModulesQuery =
    graphqlSdk.trendReportContentModules(query);
  const trendReportContentModules2Query =
    graphqlSdk.trendReportContentModules2(query);
  const globalsQuery = getGlobals(preview);

  Object.assign(props, await globalsQuery);

  setCacheHeaders(PageCacheGroups.TREND_REPORT, context);

  try {
    props.entry = await trendReportQuery;
  } catch (e) {
    const error = e as GraphQLResponseError<TrendReportQuery>;
    if (error?.response?.data) {
      props.entry = error.response.data;
    } else {
      logError(error);
      return { props };
    }
  }

  try {
    props.contentModules = await trendReportContentModulesQuery;
  } catch (error) {
    logError(error);
    props.contentModules = undefined;
  }

  try {
    props.contentModules2 = await trendReportContentModules2Query;
  } catch (error) {
    logError(error);
    props.contentModules2 = undefined;
  }

  const entryItem = props.entry.trendReportCollection
    ? props.entry.trendReportCollection.items[0]
    : null;

  if (!entryItem) {
    return { notFound: true };
  }

  return { props };
};

export default TrendReport;
